import Stepper from "../../components/Stepper/Stepper";
import { useState, useEffect, useMemo,useCallback  } from "react";
import RegistrationStepOne from "./RegistrationStepOne";
import RegistrationStepThree from "./RegistrationStepThree";
import RegistrationStepTwo from "./RegistrationStepTwo";
import {
    ManagerServices,
    setAuthToken,
    getUserDetails,
    LocationServices,
} from "../../services/supplier";
import PageLoader from "../../components/PageLoader/PageLoader";
import { toast } from "react-toastify";
import {
    registerHotel,
} from "../../services/web3Services";
import { useNavigate } from "react-router-dom";
import {addDaysTo} from "../../utils/date"
import countryList from 'react-select-country-list'


const Registration = () => {
    let navigate = useNavigate();
    const [registrationStep, setRegistrationStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [phone,setPhone] = useState('')
    const [managerImage, setManagerImage] = useState("");
    const [isEditing, setIsEditing] = useState(0);
    const [country, setCountry] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [isTransaction, setIsTransaction] = useState(false)
    const [description,setDescription] = useState('')

    const countryHandler = value => {
        setStepOneData({
            ...stepOneData,
        'country': value?.label,
        })
        setCountry(value)
        sessionStorage.setItem('country',JSON.stringify(value))
    }
    const [vatRate,setVatRate] = useState(1)
    const [cleaningFee , setCleaningFee] = useState(1)
    const [cityTax , setCityTax] = useState(1)
    const [stepOneData, setStepOneData] = useState({
        account: "",
        email: "",
        hotel: "",
        hotelAddress: "",
        city:"",
        state:"",
        country: '',
        phone: phone,
        zip: "",
        location: "",
        latitude: "",
        longitude:"",
        description: "",
        hotelStar: "3",
        tags: [],
        tagInput: "",
        websiteUrl: "",
        files: "",
        mapFile: [],
    });
    let currDate = new Date();
    currDate.setHours(0,0,0,0)
    const [stepTwoData, setStepTwoData] = useState([
        {
            name: "",
            files: [],
            mapFile: [],
            details: {
                availableRooms: 0,
                roomAreaUnit: "sq. mt.",
                occupancy: 0,
                roomSize: 0,
                description: "",
                tags: [],
            },
            pricingInfo: [
                {
                    dateFrom: currDate,
                    dateTo: addDaysTo(1,currDate),
                    minDateTo: addDaysTo(1,currDate),
                    minDateFrom:currDate,
                    price: 0,
                    minResale: 0,
                    cancellation: "",
                    percentage: 0,
                    daysBefore: 0,
                },
            ],
        },
    ]);
    const [treasuryWallet, setTreasuryWallet] = useState("");
  
    const [pricingInfo, setPricingInfo] = useState([
        {
            dateFrom: currDate,
            dateTo: addDaysTo(1,currDate),
            minDateTo: addDaysTo(1,currDate),
            minDateFrom:currDate,
            price: 0,
            minResale: 0,
            cancellation: "",
            percentage: 0,
            daysBefore: 0,
        },
    ]);
    const handleAdd = (index) => {
        const tempArr = stepTwoData

        tempArr.push({
            name: "",
            files: [],
            mapFile: [],
            details: {
                availableRooms: 0,
                roomAreaUnit: "sq. mt.",
                occupancy: 0,
                roomSize: 0,
                description: "",
                tags: [],
            },
            pricingInfo: [
                {
                    dateFrom: currDate,
                    dateTo: addDaysTo(1,currDate),
                    minDateTo: addDaysTo(1,currDate),
                    minDateFrom:currDate,
                    price: 0,
                    minResale: 0,
                    cancellation: "",
                    percentage: 0,
                    daysBefore: 0,
                },
            ]
        })

        setStepTwoData([...tempArr])
        sessionStorage.setItem("stepTwoData", JSON.stringify([...tempArr]))
        setIsEditing(tempArr?.length-1)
    };
    const mapIntoForm = () => {
        const tempArr = [];
        stepTwoData.forEach((roomData) => {
            const minPrice = Math.min(
                ...roomData.pricingInfo.map((item) => item.minResale)
            );
            const tempData = {
                roomType: roomData.name,
                roomArea: roomData.details.roomSize,
                roomAreaUnit: roomData.details.roomAreaUnit,
                numberOfRooms: roomData.details.availableRooms,
                maxOccupancy: roomData.details.occupancy,
                description: roomData.details.description,
                roomPricing: roomData.pricingInfo, //! PRICING COMPONENT
                images: roomData.files,
                attributes: {
                    hasSeaView: roomData.details.tags.includes("Sea View"),
                    hasKingBed: roomData.details.tags.includes("King Bed"),
                    hasHalfBoard: roomData.details.tags.includes("Half Board"),
                    hasFullBoard: roomData.details.tags.includes("Full Board"),
                    hasSwimmingPool: roomData.details.tags.includes("Pool"),
                    hasAirCondition: roomData.details.tags.includes("Air Conditioning"),
                    hasFreeWifi: roomData.details.tags.includes("Free Wifi"),
                },
                minPrice: minPrice, //* NO INPUT FIELD
                source: "HOTEL", //* NO INPUT FIELD
                isBundelable: null, //* NO INPUT FIELD
                isTransferable: null, //* NO INPUT FIELD
            };
            tempArr.push(tempData);
        });
        return tempArr;
    };
    const handleVatRate = (evt) => {
        if((evt.target.value < 1 || evt.target.value>50)) {
            return 
        }
        setVatRate(evt.target.value)
        sessionStorage.setItem('vatRate',evt.target.value )
        console.log("vat" , vatRate)
    }
    // const handleCleaningFee = (evt) => {
    //     if((evt.target.value < 1 || evt.target.value>50)){
    //         return 

    //     }
    //     setCleaningFee(evt.target.value)
    // }

    // const handleCityTax = (evt) => {
    //     if((evt.target.value< 1 || evt.target.value>50)){
    //         return 
    //     }
    //     setCityTax(evt.target.value)
    // }
    const handleStepOneChange = (evt) => {
        let value = evt.target.value
        setStepOneData({
            ...stepOneData,
            [evt.target.name]: value,
        })
        sessionStorage.setItem('stepOneData',JSON.stringify(stepOneData))
    };


    const [stepThreeData, setStepThreeData] = useState({});
    const [roomTags,setRoomTags] = useState([])
    const createHotel = async () => {
        let hotelId = null;
        let roomIds = [];
        let roomPricingIds = [];
        // let Tax = [{ name: "VAT", value: vatRate, isAbsolute: false }, { name: "Cleaning-Fee", value: cleaningFee, isAbsolute: false }, { name: "City-Tax", value: cityTax, isAbsolute: false }];
        // console.log("Tax" , Tax)

        try {
            setLoading(true); setIsTransaction(true)
            let roomsData = mapIntoForm();
            const latitude = stepOneData.latitude
            const longitude = stepOneData.longitude
            // const location = await LocationServices.getLocation(
            //     latitude,
            //     longitude
            //     );
            setAuthToken(getUserDetails()?.token);
            

            const getTimezone = await LocationServices.getTimezone(latitude,longitude);
            console.log('🚀 ~ file: Registration.jsx ~ line 232 ~ createHotel ~ getTimezone', getTimezone)
            const timezoneRes = await getTimezone.data.location;
            console.log('🚀 ~ file: Registration.jsx ~ line 233 ~ createHotel ~ timezoneRes', timezoneRes)

            if(!timezoneRes?.timezoneId && !timezoneRes?.gmtOffset && !timezoneRes?.rawOffset && !timezoneRes?.countryCode){
                toast.error("Please enter correct Latitude  and Longitude");
                setLoading(false)
                setIsTransaction(false)
                    //             let timezone = {
                    // timezoneId: "Europe/Lisbon",
                    // gmtOffset: 0,
                    // rawOffset: 0,
                    // countryCode: "PT"}

            }
            else{
                let timezone = {
                    timezoneId: timezoneRes?.timezoneId,
                    gmtOffset: timezoneRes?.gmtOffset,
                    rawOffset: timezoneRes?.rawOffset,
                    countryCode: timezoneRes?.countryCode
                }
                console.log('🚀 ~ file: Registration.jsx ~ line 244 ~ createHotel ~ timezone - 2', timezone)
    
                
                
                
                // return
                let hotelImages = stepOneData.files;
    
                const minPriceRoom = Math.min(
                    ...roomsData.map((item) => item.minPrice)
                );
                let hotelData = {
                    images: hotelImages,
                    name: stepOneData.hotel,
                    email: stepOneData.email,
                    accountName: stepOneData.account,
                    hotelChainId: null,
                    address: stepOneData.hotelAddress,
                    country: stepOneData.country, //* NO INPUT FIELD
                    state: stepOneData.state, //* NO INPUT FIELD
                    city: stepOneData.city, //* NO INPUT FIELD
                    zipCode: stepOneData.zip,
                    locationCordinates: {
                        longitude: longitude,
                        latitude: latitude,
                    },
                    websiteUrl: stepOneData.websiteUrl,
                    timezone:timezone,
                    phoneNumber: phone,
                    // description: stepOneData.description,
                    description: description,
                    starRatings: stepOneData.hotelStar,
                    guestRatings: "", //* NO INPUT FIELD
                    totalRooms: "", //* NO INPUT FIELD
                    walletAddress: treasuryWallet,
                    attributes: {
                        hasSwimmingPool: stepOneData.tags.includes("Pool"),
                        hasFreeWifi: stepOneData.tags.includes("Free Wifi"),
                        hasFreeParking: stepOneData.tags.includes("Parking"),
                        hasAirCondition: stepOneData.tags.includes("Air Conditioning"),
                        hasSpa: stepOneData.tags.includes("Spa"),
                        isWalletFriendly:stepOneData.tags.includes("Wallet Friendly"),
                        hasRestaurant: stepOneData.tags.includes("Restaurant")
                    },
                    minPrice: minPriceRoom,
                    fee: "", //* NO INPUT FIELD
                    rooms: roomsData,
                    treasuryWallet: treasuryWallet,
                    vat: vatRate,
                    // hotelTax: Tax,
                };
                console.log('🚀 ~ file: Registration.jsx ~ line 306 ~ createHotel ~ hotelData', hotelData)
                let attributes = {
                    cancellationCondition: hotelData?.attributes["isCancellation"],
                    nftImage: hotelData.images[0],
                    hotelOwnerAddress: getUserDetails()?.address,
                    city: hotelData.city,
                    country: hotelData.country,
                    managerEmail: hotelData.email,
                    locationCordinates: hotelData.locationCordinates,
                    starRating: hotelData.starRatings,
                    state: hotelData.state,
                    zipCode: hotelData.zipCode,
                    hotelFeatures: hotelData.attributes,
                    hasSwimmingPool: stepOneData.tags.includes("Pool"),
                        hasFreeWifi: stepOneData.tags.includes("Free Wifi"),
                        hasFreeParking: stepOneData.tags.includes("Parking"),
                        hasAirCondition: stepOneData.tags.includes("Air Conditioning"),
                        hasSpa: stepOneData.tags.includes("Spa"),
                        isWalletFriendly:stepOneData.tags.includes("Wallet Friendly"),
                        hasRestaurant: stepOneData.tags.includes("Restaurant"),
                    phoneNumber: phone,
                    // description: stepOneData.description,
                    description: description,
                    vat: vatRate,
                    // hotelTax: Tax,
                };
                console.log('🚀 ~ file: Registration.jsx ~ line 332 ~ createHotel ~ attributes', attributes)
                setAuthToken(getUserDetails()?.token);
                const creatingHotel = await ManagerServices.createHotel(hotelData);
                console.log('🚀 ~ file: Registration.jsx ~ line 335 ~ createHotel ~ creatingHotel', creatingHotel)
                hotelId = creatingHotel.data?.ids.hotelId
                roomIds = creatingHotel.data?.ids.roomId
                roomPricingIds = creatingHotel.data?.ids.roomPricingIds
                await registerHotel(
                    creatingHotel.data.hotel._id,
                    hotelData,
                    0,
                    attributes,
                    treasuryWallet
                )
                    .then(async (res) => {
                        console.log("res: ", res);
                        const uniqueId =
                            res.events.HotelRegistered.returnValues.hotelDetails
                                .index;
    
                        const updateHotelId = await ManagerServices.updateHotelId(
                            creatingHotel.data.hotel._id,
                            uniqueId
                        );
                        setLoading(false);
                        setIsTransaction(false)
    
                        toast.success("Hotel listed successfully!");
                        navigate("/");
                        // sessionStorage.remove("stepOneData")
                        // sessionStorage.remove("stepTwoData")
                        // sessionStorage.remove("userDetails")
                        // sessionStorage.remove("country")
                        // sessionStorage.remove("treasuryWallet")
                        // sessionStorage.remove("vatRate")
                        // sessionStorage.remove("phone")
    
                    })
                    .catch(async (err) => {
                        console.log("err: ", err);
                        
                        if(hotelId || roomIds.length>0) {
                            await ManagerServices.updateHotel({
                                hotelId: hotelId,
                                roomIds: roomIds,
                                roomPricingIds: roomPricingIds
                            })
                        }
                        setLoading(false);
                        setIsTransaction(false)
                        if(JSON.stringify(err?.message).includes('Manager not whitelisted')) {
                            return toast.error('Manager not whitelisted')
                        }
                        toast.error(err);
                        
                    });
            

            }
        } catch (err) {
            console.log("err: ", err);
            setLoading(false);
            setIsTransaction(false)
            if(err?.response.data.description) {
                return toast.error(err?.response.data.description)
            }
            toast.error(
                "Hotel listing error ocurred! ",
                err.response.data?.err
            );
            if(hotelId || roomIds.length>0) {
                await ManagerServices.updateHotel({
                    hotelId: hotelId,
                    roomIds: roomIds,
                    roomPricingIds: roomPricingIds
                })
            }
        }






           
    };

    
    useEffect(() => {
        if(sessionStorage.getItem("stepOneData")) {
            setStepOneData(JSON.parse(sessionStorage.getItem('stepOneData')));
        }

        if(sessionStorage.getItem('treasuryWallet')) {
            setTreasuryWallet(sessionStorage.getItem('treasuryWallet'));
        }
        if(sessionStorage.getItem('vatRate')) {
            setVatRate(Number(sessionStorage.getItem('vatRate')));
        }
        if(sessionStorage.getItem('phone')) {
            setPhone((sessionStorage.getItem('phone')));
        }
        if(sessionStorage.getItem('country')) {
            setCountry(JSON.parse(sessionStorage.getItem('country')));
        }
        if(localStorage.getItem("stepTwoData")) {
            setStepTwoData(JSON.parse(localStorage.getItem("stepTwoData")))
        }
        if(sessionStorage.getItem("hotelDescription")) {
            setDescription((sessionStorage.getItem("hotelDescription")))
        }
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        localStorage.setItem("stepTwoData",JSON.stringify(stepTwoData))
    },[stepTwoData])
    useEffect(() => {
        window.scrollTo(0, 0)
    },[registrationStep])
    return (
        <>
            {loading ? (
                <PageLoader isTransaction={isTransaction} />
            ) : (
                <div className="app flex flex-col items-center gap-8">
                    <Stepper state={registrationStep} />
                    {registrationStep === 0 && (
                        <RegistrationStepOne
                            stepData={stepOneData}
                            setStepData={setStepOneData}
                            setStep={setRegistrationStep}
                            stepOneData={stepOneData}
                            handleStepOneChange={handleStepOneChange}
                            setStepOneData={setStepOneData}
                            setManagerImage={setManagerImage}
                            managerImage={managerImage}
                            setTreasuryWallet={setTreasuryWallet}
                            treasuryWallet={treasuryWallet}
                            phoneNo={phone}
                            setPhone={setPhone}
                            setCountry={setCountry}
                            country={country}
                            countryHandler={countryHandler}
                            options={options}
                            vatRate={vatRate}
                            handleVatRate={handleVatRate}
                            // cleaningFee={cleaningFee}
                            // handleCleaningFee={handleCleaningFee}
                            // cityTax={cityTax}
                            // handleCityTax={handleCityTax}
                            description={description}
                            setDescription={setDescription}
                        />
                    )}
                    {registrationStep === 1 && (
                        <RegistrationStepTwo
                            stepData={stepTwoData}
                            setStepData={setStepTwoData}
                            setStep={setRegistrationStep}
                            handleAdd={handleAdd}
                            pricingInfo={pricingInfo}
                            setPricingInfo={setPricingInfo}
                            step={registrationStep}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            newAdd={true}
                            roomTags={roomTags}
                            setRoomTags={setRoomTags}
                            
                        />
                    )}
                    {registrationStep === 2 && (
                        <RegistrationStepThree
                            stepData={stepThreeData}
                            setStepData={setStepThreeData}
                            setStep={setRegistrationStep}
                            createHotel={createHotel}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default Registration;
