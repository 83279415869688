import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { InputAdornment, TextField } from "@mui/material";

const style = {
    position: "absolute",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: '300px',
    bgcolor: "#fff",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
};
const MobileView = (props) => {
    const handleClose = () => setOpen(false);
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    useEffect(() => {
        if(location.pathname !== '/' && props.showModal)
            setOpen(true)
    }, [location]);
    return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ola!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            BukTrips works best when browsed on a desktop - please login using a desktop to use the site
          </Typography>
        </Box>
      </Modal>
    );
};

export default MobileView;
